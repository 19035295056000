import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/Layout'
import Button from '../components/Button/Button'
import Text from '../components/Text/Text'
import Underline from '../components/Underline/Underline'
import CtA from '../components/CTA/CTA'
import ThreeCard from '../components/ThreeCard/ThreeCard'
import * as HomeStyles from './index.module.css'
import { graphql } from 'gatsby'
import TxtImg from '../components/TxtImg/TxtImg'
import Appointments from '../components/Appointments/Appointments'
import Testimonial from '../components/Testimonial/Testimonial'

function Home({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>Brixworth Osteopathic Clinic</title>
        <meta name="description" content="Brixworth Osteopaths offers easy access to professional, patient focused Osteopathic care for all ages suffering from musculoskeletal based pain, discomfort and injury." />
      </Helmet>
      <section className={HomeStyles.titleSection}>
        <StaticImage src="../images/boc-logo-vertical-white.png" placeholder="blurred" width={225} className={HomeStyles.logo} />
        <div className={HomeStyles.buttons}>
          <Button external={false} type="neutralOutline" fullWidth={true} to="/about#osteopaths">Meet the Osteopaths</Button>
          <Button external={true} type="primarySolid" fullWidth={true} to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online now</Button>
        </div>
      </section>
      <section className={HomeStyles.introSection}>
        <Text>
          <Underline>
            <h2>Welcome to the Brixworth Osteopathic&nbsp;Clinic</h2>
          </Underline>
          <p>Brixworth Osteopathic Clinic offers excellent osteopathic care for patients of all ages who may be suffering from a range of issues arising from the musculo-skeletal system.</p>
          <p>As highly trained primary care practitioners we use refined diagnostic skills and experience to treat the cause not just the symptoms of the problem.</p>
          <p>We spend the time needed to understand you, the individual, to develop a tailored well rounded rehabilitation programme, giving education and exercises as part of the treatment process.</p>
          <CtA to="/about" text="About the practice" />
        </Text>
      </section>
      <section className="condense">
        <Text>
          <Underline>
            <h2>What We Do to Help You</h2>
          </Underline>
        </Text>
        <ThreeCard
          card1ImageSrc={data.osteopathy}
          card1ImageAlt="Osteopathy treatment"
          card1Service="Osteopathy"
          card1Description="Osteopathy is a method of assessing, treating and preventing a wide range of health problems."
          card1To="/services/osteopathy/"
          card2ImageSrc={data.massage}
          card2ImageAlt="Massage treatment"
          card2Service="Sports & Remedial Massage"
          card2Description="Sports, remedial or deep tissue massage can provide many benefits - not only if you're a sports person."
          card2To="/services/sports-remedial-massage/"
          card3ImageSrc={data.cranial}
          card3ImageAlt="Cranial Osteopathy treatment"
          card3Service="Cranial Osteopathy"
          card3Description="Cranial osteopathy is a subtle and refined approach to osteopathy that uses very gentle techniques."
          card3To="/services/cranial-osteopathy/"
          card4ImageSrc={data.feeding}
          card4ImageAlt="Infant feeding coaching"
          card4Service="Infant Feeding Coaching"
          card4Description="Supporting the feeding mother and baby to find the feeding experience they are both looking for."
          card4To="/services/infant-feeding-coaching/"
        />
      </section>
      <section>
        <TxtImg imgFirst={true} split="half" imgSrc={data.collage} imgAlt="Osteopathy collage" >
          <Underline>
            <h2>What is Osteopathy?</h2>
          </Underline>
          <p>Osteopathy is a method of assessing, treating and preventing a wide range of health problems. Osteopaths use a combination of movement, stretching, targeted deep tissue massage and manipulation of a person’s muscles and joints to improve function, relieve pain and aid recovery.</p>
          <p>The principle of osteopathy is that the wellbeing of an individual relies on the way that joints, muscles, ligaments, connective tissue, blood supply and other internal structures work with each other whilst taking time to consider the emotional and physical stresses the body may be under in day to day life.</p>
          <CtA to="/services/osteopathy" text="Osteopathy at Brixworth Osteopathic Clinic" />
        </TxtImg>
      </section>
      <Testimonial name="Sophie" age="28">
        <p>Many people like me who are young and work at a desk over 5 days a week, suffer with back and shoulder pain. I was sick of being in pain every day. I suffered with intense migraines, some so bad that I have been prescribed morphine from the doctors.</p>
        <p>Gordon at Brixworth Osteopathy has literally changed my life. I'm no longer in pain, I feel young and free again, I feel active, I feel happy and I have my love for life back all because i am not in pain. I am managing my pain through osteopathy, good eating and mindful living.</p>
      </Testimonial>
      <section>
        <Appointments fees="all" />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    osteopathy: file(relativePath: {eq: "osteopathy.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 400
          placeholder: BLURRED
        )
      }
    }
    massage: file(relativePath: {eq: "massage.jpg"}) {
      childImageSharp {
        gatsbyImageData(
            width: 800
            height: 400
            placeholder: BLURRED
        )
      }
    }
    cranial: file(relativePath: {eq: "cranial.jpg"}) {
      childImageSharp {
        gatsbyImageData(
            width: 800
            height: 400
            placeholder: BLURRED
        )
      }
    }
    feeding: file(relativePath: {eq: "infant-feeding.jpg"}) {
      childImageSharp {
          gatsbyImageData(
              width: 800
              height: 400
              placeholder: BLURRED
          )
      }
    }
    collage: file(relativePath: {eq: "Collage.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
        )
      }
    }
  }
`

export default Home
