import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CtA from '../CTA/CTA'
import * as CardStyles from './ThreeCard.module.css'

function Card({ service, description, to, imageSrc, imageAlt }) {
    const image = getImage(imageSrc)
    
    return (
        <div className={CardStyles.card}>
            <div className={CardStyles.cardTop}>
                <GatsbyImage image={image} alt={imageAlt} />
            </div>
            <h4 className={CardStyles.heading}>{service}</h4>
            <p className={CardStyles.description}>{description}</p>
            <div className={CardStyles.cta}>
                <CtA to={to} text="Learn more" />
            </div>
        </div>
    )
}

function ThreeCard(props) {
    return (
        <div className={CardStyles.cardContainer}>
            <Card
                service={props.card1Service}
                description={props.card1Description}
                to={props.card1To}
                imageSrc={props.card1ImageSrc}
                imageAlt={props.card1ImageAlt}
            />
            <Card
                service={props.card2Service}
                description={props.card2Description}
                to={props.card2To}
                imageSrc={props.card2ImageSrc}
                imageAlt={props.card2ImageAlt}
            />
            <Card
                service={props.card3Service}
                description={props.card3Description}
                to={props.card3To}
                imageSrc={props.card3ImageSrc}
                imageAlt={props.card3ImageAlt}
            />
            <Card
                service={props.card4Service}
                description={props.card4Description}
                to={props.card4To}
                imageSrc={props.card4ImageSrc}
                imageAlt={props.card4ImageAlt}
            />
        </div>
    )
}

export default ThreeCard