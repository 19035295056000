import * as React from 'react'
import { Link } from 'gatsby'
import * as CTAStyles from './CTA.module.css'

function CtA({ to, text }) {
    return (
        <Link to={to} className={CTAStyles.link}>{text}&nbsp;&#8594;</Link>
    )
}

export default CtA