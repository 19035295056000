import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import * as TxtImgStyles from './TxtImg.module.css'

function TxtImg(props) {
    const image = getImage(props.imgSrc)

    return (
        <div className={TxtImgStyles[props.split]}>
            <div className={props.imgFirst ? `${TxtImgStyles.imgFirst}` : `${TxtImgStyles.txtFirst}`}>
                {props.children}
            </div>
            <div>
                <GatsbyImage image={image} alt={props.imgAlt} />
            </div>
        </div>
    )
}

TxtImg.propTypes = {
    split: PropTypes.string.isRequired,
    imgFirst: PropTypes.bool.isRequired,
    imgSrc: PropTypes.object.isRequired,
    imgAlt: PropTypes.string.isRequired,
}

export default TxtImg